.container {
  height: 100vh;
}
header {
  padding: 30px 43px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  a {
    text-decoration: none;
    color: #ffffff;
  }
  .logo {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
  }
  .home-a {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    border-radius: 18px;
    border: 1px solid #fa1c44;
    background: linear-gradient(258deg, #fa1c44 -36.43%, #1e1e1e 97.22%);
    padding: 14px 16px;
    /* Blur/Full */
    backdrop-filter: blur(27px);
    display: none;
  }
}
main {
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 36px 24px;
  display: grid;
  section.left {
    img {
      display: block;
      margin: 0 auto;
      width: 100%;
    }
    h1 {
      color: #fff;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 39px */
      margin-top: 26px;
    }
    p {
      margin-top: 13px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
  }
  section.right {
    .socials {
      text-align: center;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
      p {
        color: #fff;
        font-family: Commissioner;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .form-root {
      label.MuiFormLabel-root.MuiInputLabel-root {
        color: #ffffff;
        font-weight: normal;
      }
      .form-title {
        font-weight: 700;
        font-size: 18px;
      }
      padding: 26px;
      .form-controls {
        margin-top: 1rem;
      }
    }
    .form-border {
      border-radius: 20px;
      border: 2px solid #717273;
      background: linear-gradient(180deg, rgba(142, 142, 139, 0.24) 0%, rgba(0, 0, 0, 0.67) 100%);
      backdrop-filter: blur(20px);
    }
  }
}

@media screen and (min-width: 1200px) {
  header {
    justify-content: space-between;
    .home-a {
      display: block;
    }
  }
  main {
    grid-template-columns: 2fr 418px;
    gap: 92px;
    padding: 84px 100px;
    section.left {
      h1 {
        font-size: 44px;
      }
      p {
        font-size: 18px;
      }
    }
    section.right {
      .socials {
        text-align: left;
      }
    }
  }
}
